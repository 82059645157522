import React from "react";
import logo from "../../assets/images/Header/cmo_logo_feher_fekete_gradient_szinek_NEW_OLD-11.svg";
import ExpansionPanel from "../../components/ExpansionPanel/ExpansionPanel";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Button from "../../components/Button/Button";
import "./popups.scss";
import { navigate } from "gatsby";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const Popups = (props) => {
  const {
    primary_color,
    secondary_color,
    title,
    content,
    button_text,
    button_url,
    expansionPanel,
  } = props.pageContext.content;

  return (
    <div
      className="popups-wrapper"
      style={{
        background: `radial-gradient(99.85% 48.22% at -0.88% 23.94%, ${primary_color} 0%, ${primary_color} 43.23%, ${secondary_color} 100%)`,
      }}
    >
      <a href="/" className="logo">
        <img src={logo} alt="cmo-logo" />
      </a>
      <div className="body-content">
        <div
          className="back-button"
          role="presentation"
          onClick={() => {
            navigate(-1);
          }}
          onKeyDown={() => {
            navigate(-1);
          }}
        >
          <div className="image-wrapper">
            <ChevronLeftIcon />
          </div>
          <div className="back">
            <p className="back-titl">Elolvastad amire kiváncsi voltál?</p>
            <p className="back-subtitle">
              Kattints ide, hogy visszatérj az előző oldalra!
            </p>
          </div>
        </div>
        <div className="content">
          <h1 className="title">{title}</h1>
          <div className="description">
            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={content} />
          </div>
          {button_text && button_url && (
            <div className="button-wrapper">
              <a href={button_url}>
                <Button
                  variant={"contained"}
                  background_color={"#5107FF"}
                  color={"white"}
                  text={button_text}
                ></Button>
              </a>
            </div>
          )}
          {expansionPanel?.items.length > 0 && (
            <>
              <h1 className="titl">{expansionPanel.title}</h1>
              <ExpansionPanel
                content={{
                  items: expansionPanel.items,
                }}
                background_color="white"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popups;
