import React from "react";
import LayoutComponent from "../LayoutComponent/LayoutComponent";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ReactMarkdown from "react-markdown";

import "./expansionPanel.scss";
import rehypeRaw from "rehype-raw";

const ExpansionPanel = ({ content, background_color }) => {
  return (
    <div className="expansionPanel-wrapper">
      <LayoutComponent background_color={background_color}>
        <div className="body-content">
          {content.title && <h1 className="center">{content.title}</h1>}
          <div>
            {content.items.map((item, index) => {
              return (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div>{item.question}</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <ReactMarkdown
                        rehypePlugins={[rehypeRaw]}
                        children={item.answer}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default ExpansionPanel;
